<template>
  <div>
    <table class="text-left -ml-2" :class="layoutFixed ? 'table-fixed' : ''">
      <thead class="text-gray-dark text-semibold">
        <th
          v-for="(column, i) in columns"
          v-bind:key="`column-head-${i}`"
          :class="column.className"
          class="p-2 font-display1"
        >{{ column.title }}</th>
      </thead>
      <tbody>
        <tr
          v-for="(item, itemIndex) in items"
          v-bind:key="`item-row-${itemIndex}`"
          :data-auto-id="`item-row-${itemIndex}`"
          :id="`item-row-${itemIndex}`"
          @click="$emit('rowClick', item)"
          class="odd:bg-white even:bg-gray-lightest"
          :class="{ 'cursor-pointer': clickRow }"
        >
          <td v-for="(column, i) in columns" v-bind:key="`item-${itemIndex}-${i}`" class="p-2">
            <slot
              :name="column.slot"
              v-bind:itemData="item[column.target]"
            >{{ item[column.target] }}</slot>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="hasMore || loading" class="h-8">
      <a
        href="#!"
        @click.prevent="$emit('more')"
        v-if="hasMore && !loading"
        class="flex flex-col items-center text-gray-dark no-underline hover:text-gray-darkest mt-2"
      >
        <div class="text-tiny -mb-2">Load More</div>
        <i class="material-icons-outlined md-24 micon">expand_more</i>
      </a>
      <div v-if="loading" class="text-center mt-2">
        <ToznyLoader v-if="loading" class="w-8 h-8 text-tozny" />
      </div>
    </div>
  </div>
</template>

<script>
import ToznyLoader from '@/Common/ToznyLoader'
export default {
  name: 'ItemTable',
  components: {
    ToznyLoader,
  },
  props: {
    items: {
      type: Array,
      validator: items => items.every(item => item),
    },
    columns: {
      type: Array,
      validator: items =>
        items.every(
          item =>
            (item.title || item.title === '') &&
            (item.target || item.target === '') &&
            item.slot
        ),
    },
    hasMore: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    clickRow: Boolean,
    layoutFixed: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped lang="scss">
table {
  width: calc(100% + 1rem);
}
</style>
